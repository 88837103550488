import { Injectable } from '@angular/core';

import { CanDeactivateComponent } from './can-deactivate.interface';

@Injectable()
export class CanDeactivateGuard  {
    
    canDeactivate(component: CanDeactivateComponent): boolean {
        if (!localStorage.getItem('lastActivity')) {
            return true;
        }
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
