export const environment = {
  production: false,
  api: 'https://devservices.sequation.net',
  enableCtrl: true,
  moodleUrl: 'https://devservices.sequation.net',
  azureBlobStorage: 'https://sequationdevsa.blob.core.windows.net',
  appInsights: {
    instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b'
  },
  suUrl: 'dev-educationwallet.hmpps.college',
  logo: `./assets/logo/keyworker_logo.png`,
  appTitle: 'Education Wallet',
  issuer: 'https://login.microsoftonline.com/5778ddf3-0ec2-4912-9f7c-6eb3674b2682',
  clientId: '647e0c82-05f7-4a33-93f5-430cec0d93f2',
  redirectUri:'https://dev-educationwallet.hmpps.college/sessions/signin',
  kwUrl:'dev-teacher-educationwallet.hmpps.college',
  kwIssuer: 'https://login.microsoftonline.com/5778ddf3-0ec2-4912-9f7c-6eb3674b2682',
  kwClientId: '79b4d9e7-696b-44d3-afed-d21c44ca30ed',
  kwRedirectUri: 'https://dev-teacher-educationwallet.hmpps.college/sessions/signin'
};
