import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customMonthFormat'
})
export class CustomMonthFormatPipe implements PipeTransform {
  transform(value: string): string {
    // Check if the value is null
    if (value === null) {
      console.error('Input value is null');
      return ''; // Return an empty string or another default value
    }

    console.log(value, "value in pipe");

    const monthAbbreviations = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];

    try {
      const dateObject = new Date(value);

      // Check if the date is valid
      if (isNaN(dateObject.getTime())) {
        console.error('Invalid date format:', value);
        return ''; // Return an empty string or another default value
      }

      const dayNumber = dateObject.getDate();
      const monthNumber = dateObject.getMonth();
      const yearNumber = dateObject.getFullYear();

      console.log(dayNumber, "dayNumberdayNumber");

      return dayNumber + " " + monthAbbreviations[monthNumber] + " " + yearNumber;
    } catch (error) {
      console.error('Error parsing date:', value, error);
      return ''; // Return an empty string or another default value
    }
  }
}
