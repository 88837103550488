import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SessionsService } from './sessions/sessions.service';
import { HttpRequestInterceptorModule } from './framework/service/http-interceptor.service';
import { AuthenticationGuard } from './framework/guards/authentication.guard';
import { StepperNavigationModule } from './features/shared/components/stepper-navigation/stepper-navigation.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SharedModule } from './framework/shared/shared.module';
import { UserActivityModule } from './sessions/user-activity/user-activity.module';
import { AuthorizationGuard } from './framework/guards/authorization.guard';
import { MaterialModule } from './framework/material/material.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from './framework/pipes/filter.module';
import { RoutePartsService } from './shared/services/route-parts.service';

import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { SharedDataService } from './shared/services/shared-service';
import { S } from '@angular/cdk/keycodes';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.issuer,
      redirectUri: environment.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    }
  });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpRequestInterceptorModule,
    StepperNavigationModule,
    UserActivityModule,
    TranslateModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FilterPipeModule,
    MsalModule
  ],
  providers: [SessionsService , AuthenticationGuard , AuthorizationGuard, RoutePartsService,SharedDataService,
   
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('seq-theme');
  }
}
