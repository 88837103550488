import { CommonModule } from '@angular/common';
import { StepperNavigationComponent } from './stepper-navigation.component';
import { StepperNavigationService } from './stepper-navigation.service';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../../framework/material/material.module';
import { FeatureAllowModule } from '../directives/features-allow.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [StepperNavigationComponent],
    imports: [
        CommonModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        FeatureAllowModule,
        MaterialModule,
        TranslateModule,
        RouterModule
    ],
    providers: [StepperNavigationService],
    exports: [StepperNavigationComponent]
})
export class StepperNavigationModule { }
