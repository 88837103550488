import { NgModule } from '@angular/core';
import { AppConfirmService } from './app-confirm.service';
import { AppConfirmComponent } from './app-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyRadioButton as MatRadioButton, MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';


@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatSelectModule, MatRadioModule],
    declarations: [AppConfirmComponent],
    providers: [AppConfirmService],
    exports: [AppConfirmComponent]
})
  export class AppConfirmModule { }
