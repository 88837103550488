import { Component, OnInit, HostListener, Inject, Renderer2 } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { AppInsightsService } from './framework/service/app-insights.service';
import { BaseUrl } from './framework/constants/url-constants';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
//import { RoutePartsService } from './framework/service/route-parts.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { SessionsService } from './sessions/sessions.service';
import { environment } from 'src/environments/environment';
import { RoutePartsService } from './shared/services/route-parts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  appTitle = BaseUrl.APPTITLE;
  public spinkit = Spinkit;
  pageTitle = '';
  clientDetails;
  logo: string;
  isPes: Boolean = false;
  appId: string;
  relode: boolean = false;
  isPageReloaded: boolean;

  constructor(
    public title: Title,
    private readonly appInsightsService: AppInsightsService,
    private readonly router: Router,
    private readonly routePartsService: RoutePartsService,
    private readonly activeRoute: ActivatedRoute,
    private readonly sessionsService: SessionsService
  ) {
    window.onbeforeunload = () => {
      localStorage.setItem('lastClosedURL', window.location.href);
    };
    this.getIsPes();
  }

  ngOnInit() {
    if (localStorage.getItem('token') && localStorage.getItem('lastClosedURL')) {
      this.navigateToUrl();
    }
    this.setInitialData();
    this.changePageTitle();
    this.onload();
    if (!environment.enableCtrl) {
      this.disableControls();
    }
  }

  navigateToUrl() {
    const lastClosedURL = localStorage.getItem('lastClosedURL');
    if (lastClosedURL) {
      // Split the URL into host and path
      const urlParts = lastClosedURL.split('/');
      if (urlParts.length > 2) { // Ensure host part exists
        // Extract the path (excluding host part)
        const path = urlParts.slice(3).join('/');
        console.log("In app ts", path);
        // Navigate to the desired path
        this.router.navigateByUrl('/' + path);
      }
    }
  }

  @HostListener('keyup', ['$event']) keyUpEvent(event: Event) {
    this.appInsightsService.logEvent("" + event.type);
  }
  @HostListener('click', ['$event']) clickEvent(event: Event) {
    this.appInsightsService.logEvent("" + event.type);
  }
  @HostListener('dblclick', ['$event']) doubleClick(event: Event) {
    this.appInsightsService.logEvent("" + event.type);
  }
  @HostListener('submit', ['$event']) submit(event: Event) {
    this.appInsightsService.logEvent("" + event.type);
  }
  @HostListener('drag', ['$event']) drag(event: Event) {
    this.appInsightsService.logEvent("" + event.type);
  }
  @HostListener('drop', ['$event']) drop(event: Event) {
    this.appInsightsService.logEvent("" + event.type);
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      this.appInsightsService.logPageView(window.location.hash.split('/')[1]);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }

      routeParts.reverse();
      this.pageTitle = routeParts
        .map((part) => part.title)
        .reduce((partA, partI) => `${partI}`);
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  onload() {
    document.body.classList.toggle('dark-mode', localStorage.getItem('darkmode') === 'true');
  }


  setInitialData() {
    document.documentElement.style.setProperty('--primary-color', '#0b0c0c');
    document.documentElement.style.setProperty('--secondary-color', '#1d70b8');
  }

  getIsPes() {
    this.sessionsService.getIsPes().subscribe(res => {
      this.isPes = res;
      if (this.isPes) {
        localStorage.setItem('IsPES', 'true');
      } else {
        localStorage.setItem('IsPES', 'false');
      }
    });
  }

  disableControls() {

    function keyPressCheck(event) {
      if (event.keyCode === 123) {
        return false;
      }
    }

    document.onkeypress = keyPressCheck;
    document.onkeydown = keyPressCheck;

    document.oncontextmenu = function () {
      return false;
    }

    function disableCtrlKeyCombination(e) {
      //list all CTRL + key combinations you want to disable
      const forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w');
      let key, isCtrl;

      if (window.event) {
        key = (<any>window.event).keyCode; //IE
        if ((<any>window.event).ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      } else {
        key = e.which; //firefox
        if (e.ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      }
      //if ctrl is pressed check if other key is in forbidenKeys array
      if (isCtrl) {
        for (const i of forbiddenKeys) {
          //case-insensitive comparation
          if (forbiddenKeys[i].toLowerCase() === String.fromCharCode(key).toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    }
  }

  navigateTo() {
    this.appId = localStorage.getItem('appId')
    if (this.appId == '9') {
      this.router.navigate(['/file-storage'], { queryParams: { MyWallets: 'myWallets' } });
    } else {
      this.router.navigate(['/file-storage'], { queryParams: { Home: 'home' } });
    }
  }

}

