import { environment } from "src/environments/environment";


export const BaseUrl = {
    AUTHENTICATION: environment.api + '/sequation-authentication-api',
    AUTHENTICATE: environment.api +'/sequation-user-api',
    PROFILE: environment.api + '/sequation-usermanagement-composer-api',
    USER_MANAGEMENT: environment.api + '/sequation-user-management-api',
    USER: environment.api + '/sequation-user-api',
    DOCUMENT: environment.api + '/sequation-document-api',
    LOGO: environment.logo,
    APPTITLE: environment.appTitle,
    AUTHORIZATION: environment.api + '/sequation-authorization-api',
    azureBlobStorage: environment.azureBlobStorage,
    CLIENT_URL: window.location.host,
    Document: environment.api + '/sequation-document-api'
   
};
